@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;
* {
  font-family: "Outfit", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  transition: all 500ms;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
}

:root {
  --primary-color: #007DD6;
  --primary-50: #D6EEFF;
  --primary-25: #D9E8F2;
  --gray-color: #808080;
  --gray-50: #E9E9E9;
  --gray-100: #7E7E7E;
  --bluish-gray-color: #383948;
  --gray-500: #212121;
  --primary-gradient: linear-gradient(180deg, #38ACFF 0%, #007DD6 100%), #007DD6;
  --primary-hover-gradient: linear-gradient(180deg, #007DD6 0%, #38ACFF 100%), #007DD6;
}

a.text-gray,
p.text-gray {
  color: #474747 !important;
}

.text-gray-dark {
  color: #212121;
}

.heading-three {
  font-size: 39px;
}

.heading-four {
  font-size: 31px;
}

.heading-five {
  font-size: 20px;
}

.heading-six {
  font-size: 18px;
}

.base-text {
  font-size: 16px;
}

.text-gray50 {
  color: var(--gray-50);
}

.text-gray100 {
  color: var(--gray-100);
}

.text-primary50 {
  color: var(--primary-50);
}

.text-primary25 {
  color: var(--primary-25);
}

.text-primary500 {
  color: var(--primary-color) !important;
}

.text-gray500 {
  color: var(--gray-500);
}

.body {
  font-size: 16px;
}

.header-links {
  list-style-type: none;
  gap: 32px;
  color: #C1C1C1;
}
.header-links .active {
  color: #ffffff;
}
.header-links :hover {
  color: #ffffff;
  transition: all 500ms;
}

#project-video,
#services-background-video,
#background-video {
  z-index: -1000;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.teammate .portrait {
  height: 600px;
  object-fit: cover;
  object-position: center top;
}

.hero-section {
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 20%), linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(90deg, rgba(0, 46, 92, 0.5) 0%, rgba(20, 86, 132, 0.5) 100%), linear-gradient(90deg, rgba(0, 46, 92, 0.5) 0%, rgba(20, 86, 132, 0.5) 100%), url(../images/nurse-portrait-hospital.png);
  height: 100dvh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: right;
  z-index: 2000;
  background-size: contain;
}
.hero-section .hero-texts {
  color: #ffffff;
  max-width: 674px;
  text-align: center;
}

.navbar {
  background-color: transparent;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 3000;
}
.navbar.text-gray a {
  color: #A4A4A4;
}
.navbar.text-gray a :hover {
  color: #212121 !important;
}
.navbar.text-gray a.btn-primary {
  color: #ffffff;
}
.navbar.text-gray .active {
  color: var(--primary-color) !important;
}

.bg-white {
  background-color: #ffffff !important;
}

.btn {
  display: flex;
  padding: 12px 24px;
  height: max-content;
  text-wrap: nowrap;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 16px;
  border-radius: 50px;
  transition: all 300ms ease;
}
.btn:hover {
  gap: 14px;
  transform: scale(1.03);
}
.btn.disabled {
  pointer-events: none;
  opacity: 0.3;
  cursor: not-allowed !important;
}

.btn-primary {
  background: var(--primary-color);
  transition: all 500ms ease;
  color: #ffffff;
  background-size: 1px 50px;
}
.btn-primary:hover {
  background-size: 1px 100px;
}

.btn-sm {
  font-size: 12px;
}

.btn-link {
  color: var(--primary-color);
  padding: 0px;
}

.btn-secondary {
  border: 1px solid rgba(191, 207, 217, 0.3);
  background: rgba(255, 255, 255, 0.1);
}

.scroll-cta {
  position: absolute;
  bottom: 5%;
  left: 2%;
}
.scroll-cta .text {
  color: #ffffff;
}

.message-cta .text {
  color: #ffffff;
}

.logo {
  height: 22px;
}

.about-section {
  background: #F7FBFE;
  background-image: url(../images/pattern+bg.png);
  background-repeat: no-repeat;
  background-blend-mode: luminosity;
}

.thumbnail {
  display: flex;
  height: 578px !important;
  justify-content: flex-end;
  background-color: rgba(0, 0, 0, 0.1098039216);
  background-blend-mode: multiply;
  background-size: 150vh;
  background-position: center;
}
.thumbnail .title {
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 0%);
}
.thumbnail .desc {
  margin-bottom: -20%;
  color: transparent;
}
.thumbnail:hover {
  background-size: 130vh;
  transition: all 800ms;
}
.thumbnail:hover .desc {
  color: #ffffff;
  margin-bottom: 0%;
}
.thumbnail:hover .title {
  transition: all 700ms;
  filter: blur(20);
  background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 70%);
}

.consultation {
  background-image: url(../images/Consultation.webp);
  background-repeat: no-repeat;
}

.solution-section {
  padding-top: 15rem !important;
  padding-bottom: 10rem !important;
}

.solution-section,
.s-inner {
  background-color: #F7FBFE;
  overflow: hidden;
}
.solution-section img, .solution-section h4, .solution-section p, .solution-section .chip, .solution-section .chip-fixed,
.s-inner img,
.s-inner h4,
.s-inner p,
.s-inner .chip,
.s-inner .chip-fixed {
  z-index: 4;
}

.chip {
  border: 1px solid #efeff3;
  box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
  width: 348px;
}

.chip-fixed {
  border: 1px solid #efeff3;
  box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
  width: 348px;
}

.mobile-nav {
  position: fixed;
  width: 100%;
  height: 100vh;
  z-index: 2000;
}

.mobile-overlay {
  width: 100%;
  position: fixed;
  height: 100vh;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6588235294);
  backdrop-filter: blur(20px);
}

.crescent {
  width: 587px;
  max-width: 100%;
  height: 587px;
  border-radius: 100%;
  position: absolute;
  background: linear-gradient(180deg, rgba(76, 155, 215, 0.348) -77.97%, rgba(247, 251, 254, 0.42) 57.56%);
}

.high-contrast {
  border-radius: 12px;
  padding: 12px;
}
.high-contrast:hover {
  color: #ffffff !important;
  background-color: #474747;
  transform: scale(1.01);
  box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
}

.teammate img {
  filter: grayscale(1);
  transition: all 500ms ease;
}
.teammate img:hover {
  filter: grayscale(0);
}

.project-card {
  display: flex;
  padding: 16px;
  gap: 16px;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 8px;
  border: 1px solid #EFEFF3;
  background: #FFF;
  box-shadow: 0px 1px 5px 2px rgba(241, 241, 241, 0.5);
}
.project-card .image {
  height: 391.597px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.project-card:hover .image img {
  margin-top: 30px;
}

.bgPurple {
  background-color: #F6F4FB;
}

.bgBlue {
  background-color: #F6FBFE;
}

.tag {
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  border-radius: 50px;
  width: max-content;
  color: #7E7E7E;
  border: 1px solid #F1F1F1;
  background: linear-gradient(180deg, #FFF 0%, #F7F7F7 100%);
  box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.05);
}
.tag.alt {
  background: rgba(255, 255, 255, 0.2);
  box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.05);
  border: none;
  color: #ffffff;
  padding: 4px 10px;
}

.jumbotron {
  background: url(../images/glass+effect.webp);
  background-repeat: no-repeat;
  background-position-x: 50vh;
  background-position-y: center;
  background-size: cover;
  background-color: #A0CFFF;
}
.jumbotron h4 {
  color: #002E5C;
}
.jumbotron p {
  color: #3C638B;
}

.about-desc {
  font-size: 14px;
  background: linear-gradient(180deg, #808080 0%, rgba(128, 128, 128, 0) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.nav-link {
  color: #808080;
  font-size: 14px;
  transition: all 300ms ease;
}
.nav-link:hover {
  color: var(--primary-color);
  text-decoration: underline;
}

.contact-hero .hero-inner {
  max-width: 50%;
}

.socials img {
  height: 24px;
}

.client-section {
  background-image: url(../images/pattern+bg+flipped.png);
  background-repeat: no-repeat;
  background-position: right;
}

.client {
  display: flex;
  border: 1px solid #ffffff;
  height: 133px;
  padding: 44.976px 11.048px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  filter: grayscale(1);
  background: #FFF;
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
}
.client:hover {
  border: 1px solid var(--primary-color);
  filter: grayscale(0);
}
.client:hover img {
  transform: scale(0.9);
}

.approach-section {
  background: linear-gradient(90deg, rgba(0, 46, 92, 0.92) 0%, rgba(20, 86, 132, 0.92) 100%), url(../images/team+bg.webp) 50%;
  background-size: cover;
  background-repeat: no-repeat;
  overflow: visible;
  background-attachment: fixed;
}
.approach-section .approach {
  overflow: visible;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  /* Hide scrollbar for Chrome, Safari and Opera */
}
.approach-section .approach::-webkit-scrollbar {
  display: none;
}

.approach-text {
  top: 1;
  overflow: visible;
  height: 300px;
}

.contact-section {
  background: linear-gradient(90deg, rgb(0, 46, 92) 0%, rgb(20, 86, 132) 100%);
  background-size: contain;
  background-repeat: no-repeat;
  height: 100dvh;
  position: relative;
  overflow: hidden;
}

.map {
  bottom: 10%;
  right: -25%;
  height: 450px;
  opacity: 0.4;
  position: absolute;
}

.project-section {
  background: linear-gradient(90deg, rgba(0, 46, 92, 0.92) 10%, rgba(20, 86, 132, 0.92) 100%);
  background-size: cover;
  background-repeat: no-repeat;
  height: 100dvh;
}

.blue-alt-card,
.blue-card {
  height: 265px;
  padding: 32px 33px 51px 32px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  align-self: stretch;
  border-radius: 9px;
  background: #094A80;
  box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.1);
}

.form {
  display: flex;
  padding: 24px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
  border-radius: 15px;
  background: #FCFCFC;
}
.form .f-inner {
  display: flex;
  width: 580px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  border-radius: 10px;
  border: 1px solid #EFEFF3;
  background: #FFF;
}

.form-control {
  display: flex;
  position: relative;
  outline: none;
  padding: 12px 12px 16px;
  align-items: center;
  align-self: stretch;
  border-radius: 7px;
  border: 1px solid #F1F1F1;
  background: #FFF;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);
}
.form-control:focus {
  border-color: var(--primary-color);
}

.form-group {
  position: relative;
}
.form-group .form-control {
  padding-left: 45px;
}
.form-group img {
  position: absolute;
  top: 50%;
  left: 2%;
}

.story-section {
  background: linear-gradient(90deg, rgb(0, 46, 92) 0%, rgb(20, 86, 132) 100%), url(../svgs/bg/glass-effect.svg) 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position-x: left;
  height: 100vh;
  position: relative;
  overflow: hidden;
}
.story-section .glass-effect {
  top: -40%;
  left: -30%;
  height: 130rem;
  position: absolute;
}

.mission-section {
  background-image: url(../images/pattern+bg.png);
  background-repeat: no-repeat;
  height: 100vh;
}

.single-service-section,
.values-section {
  background: linear-gradient(90deg, rgba(0, 46, 92, 0.92) 0%, rgba(20, 86, 132, 0.92) 100%), url(../svgs/bg/shadow-effect.svg) 50%;
  color: #ffffff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-x: left;
}

.features-section,
.service-list,
.about-us-section {
  background-image: url(../svgs/bg/wave+bg.svg);
  background-repeat: no-repeat;
  background-color: #ffffff !important;
  background-size: cover;
}

.features-section,
.service-list {
  background-size: contain;
}

.services-top-section {
  background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0) 20%), linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0) 50%), linear-gradient(90deg, rgba(0, 46, 92, 0.5) 0%, rgba(20, 86, 132, 0.5) 100%), linear-gradient(90deg, rgba(0, 46, 92, 0.5) 0%, rgba(20, 86, 132, 0.5) 100%);
  height: 100dvh;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position-x: right;
  z-index: 2000;
  background-size: contain;
}
.services-top-section .hero-texts {
  color: #ffffff;
  max-width: 674px;
  text-align: center;
}

.swiper-button-prev,
.swiper-button-next {
  background: rgba(0, 0, 0, 0.1607843137);
  border-radius: 50px;
  padding: 30px;
  color: #fff !important;
}
.swiper-button-prev::after,
.swiper-button-next::after {
  font-size: 24px !important;
}

.service-card {
  height: 514px;
  background-size: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
}
.service-card::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 125, 214, 0.4784313725);
  transition: all 200ms ease-in;
}
.service-card .details {
  margin-bottom: -15%;
  padding: 32px;
  background-color: #ffffff;
  border-radius: 3px;
  border: 1px solid var(--Neutral-colors-400, #E1E4ED);
  background: var(--Neutral-colors-100, #FFF);
  max-width: 90%;
  /* Neutral/Shadow 02 */
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
  z-index: 100;
}
.service-card .details a {
  filter: grayscale(0) !important;
}
.service-card:hover {
  filter: grayscale(0);
}
.service-card:hover .details {
  margin-bottom: -10%;
}
.service-card:hover::after {
  background-color: transparent;
}

.single-service-section .big-image {
  height: 80vh;
  width: 100vw;
  background-image: url(../images/Consultation.webp);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
.single-service-section .big-image::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 125, 214, 0.3019607843);
  transition: all 500ms ease;
}

.features-card {
  padding: 16px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 3px;
  background: var(--Neutral-colors-100, #FFF);
  /* Neutral/Shadow 02 */
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
}
.features-card .image {
  position: relative;
  overflow: hidden;
  height: 300px;
}
.features-card .image img {
  filter: grayscale(1);
  width: 100%;
}
.features-card .image::after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 125, 214, 0.3019607843);
  transition: all 500ms ease;
}
.features-card:hover .image::after {
  height: 0%;
  background-color: rgba(0, 125, 214, 0.5764705882);
}
.features-card:hover img {
  transform: scale(1.1);
  filter: grayscale(0);
}

.benefit-card {
  padding: 40px 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 2px;
  background: #F8F8F8;
  /* Neutral/Shadow 02 */
  box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
}
.benefit-card .position {
  color: var(--Foundation-Grey-G50, #E9E9E9);
  font-size: 49px;
  font-style: normal;
  font-weight: 400;
}

.jumbotron-alt {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 400px;
  align-items: center;
  position: relative;
  z-index: 1000;
}
.jumbotron-alt:hover video {
  filter: blur(20px);
}
.jumbotron-alt::after {
  content: "";
  position: absolute;
  bottom: 0;
  z-index: 0;
  height: 100%;
  width: 100%;
  transition: all 500ms ease;
  background: rgba(0, 0, 0, 0.7) 100%;
}
.jumbotron-alt .info {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  /* For mobile phones: */
  .heading-three {
    font-size: 21px;
  }
  p {
    font-size: 13px;
  }
  .jumbotron-alt::after {
    top: 50%;
    transform: translateY(-50%);
    height: 58%;
  }
  .features-card .image {
    height: 200px;
  }
  .scroll-cta {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
  }
  .scroll-cta .text {
    color: #ffffff;
  }
  .contact-section {
    height: 100%;
  }
  .form {
    width: 100%;
    padding: 8px;
  }
  .form form {
    width: 100%;
  }
  .form .f-inner {
    width: 100%;
  }
  .single-service-section {
    height: 100%;
  }
  .jumbotron-alt video {
    width: 50rem !important;
  }
  .jumbotron-alt .info {
    width: 100%;
  }
  .services-top-section {
    background-size: cover;
    background-position-x: center;
  }
  .hero-section {
    background-size: cover;
  }
  .blue-alt-card,
  .blue-card {
    height: max-content;
    padding: 24px;
  }
  .values-section,
  .mission-section,
  .story-section {
    height: 100%;
  }
  .vision-vid video,
  .mission-vid video {
    height: 230px !important;
  }
  .project-card .image {
    height: 200px;
  }
  .project-card .logo {
    height: 32px !important;
  }
}

