@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
    font-family: "Outfit", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
    scroll-behavior: smooth;
    transition: all 500ms ;
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);;
}

:root{
    --primary-color: #007DD6;
    --primary-50: #D6EEFF;
    --primary-25: #D9E8F2;
    --gray-color: #808080;
    --gray-50 : #E9E9E9;
    --gray-100 : #7E7E7E;
    --bluish-gray-color: #383948;
    --gray-500 : #212121;
    --primary-gradient: linear-gradient(180deg, #38ACFF 0%, #007DD6 100%), #007DD6;
    --primary-hover-gradient: linear-gradient(180deg, #007DD6 0%, #38ACFF 100%), #007DD6;
    
}


body{
    // overflow-x: hidden;
}

a.text-gray,
p.text-gray{
    color: #474747 !important;
}

.text-gray-dark{
    color: #212121;
}

// text styles

.heading-three{
    font-size: 39px;
}

.heading-four{
    font-size: 31px;
}

.heading-five{
    font-size: 20px;
}

.heading-six{
    font-size: 18px;
    // color: var(--primary-color);
}

.base-text{
    font-size: 16px;
}



// colors

.text-gray50{
    color: var(--gray-50);
}

.text-gray100{
    color: var(--gray-100);
}

.text-primary50{
    color: var(--primary-50);
}

.text-primary25{
    color: var(--primary-25);
}

.text-primary500{
    color: var(--primary-color) !important;
}

.text-gray500{
    color: var(--gray-500);
}

.body{
    font-size: 16px;
}

.header-links{
    list-style-type: none;
    // display: flex;
    gap: 32px;
    color: #C1C1C1;

    .active{
        color: #ffffff;
    }

    :hover{
        color: #ffffff;
        transition: all 500ms;
    }

}

#project-video,
#services-background-video,
#background-video{
    z-index: -1000;
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    object-fit: cover;
}

.teammate .portrait{
    height: 600px;
    object-fit: cover;
    object-position: center top;

}

.hero-section{
    background: 
    linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 20%), 
    linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), 
    linear-gradient(90deg, rgba(0, 46, 92, 0.50) 0%, rgba(20, 86, 132, 0.50) 100%), 
    linear-gradient(90deg, rgba(0, 46, 92, 0.50) 0%, rgba(20, 86, 132, 0.50) 100%),
    url(../images/nurse-portrait-hospital.png);
    // url(../gifs/futuristic+3d+geometry.gif);
    height: 100dvh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: right;
    z-index: 2000;
    background-size: contain;

    .hero-texts{
        color: #ffffff;
        max-width: 674px;
        text-align: center;
    }

}

.navbar{
    background-color: transparent;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 3000;

    &.text-gray{
        a{
            color: #A4A4A4 ;

            :hover{
                color: #212121 !important;
            }

            &.btn-primary{
                color: #ffffff;
            }
        }

        .active{
            color: var(--primary-color) !important;
        }
    }
     
    // background-color: rgba(20, 86, 132, 0.50);
    
    // 👇🏽 unhide this to see the gradient blue on navbar

    // background: linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 100%), linear-gradient(90deg, rgba(0, 46, 92, 0.50) 0%, rgba(20, 86, 132, 0.50) 100%);
}

.bg-white{
    background-color: #ffffff !important;
}

// Primary Button

.btn{
    display: flex;
    padding: 12px 24px;
    height: max-content;
    text-wrap: nowrap;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 16px;
    // border-radius: 7px;
    border-radius: 50px;
    transition: all 300ms ease;
    
    &:hover{
        gap: 14px;
        transform: scale(1.03);
    }

    &.disabled{
        pointer-events: none;
        opacity: .3;
        cursor: not-allowed !important;
    }
}

.btn-primary{
    // background: var(--primary-gradient);
    background: var(--primary-color);
    transition: all 500ms ease;
    color: #ffffff;
    // background-position: 0px;
    background-size: 1px 50px; 


    &:hover{
        // background: var(--primary-hover-gradient);
        // background: var(--primary-color);
        background-size:1px 100px; 
        
    }
}

.btn-sm{
    font-size: 12px;
}

.btn-link{

    color: var(--primary-color);
    padding: 0px;
    // &::after{
    //     content: '\f061';
    // }
}

//secondary button

.btn-secondary{
    // border-radius: 10px;
    border: 1px solid rgba(191, 207, 217, 0.30);
    background: rgba(255, 255, 255, 0.10);
    // box-shadow: 0px 0px 4px 1px rgba(255, 255, 255, 0.20);
}


.scroll-cta{
    position: absolute;
    bottom: 5%;
    left: 2%;

    .text{
        color: #ffffff;
    }
}

.message-cta{
    .text{
        color: #ffffff;
    }
}

.logo{
    height: 22px;
}

.about-section{
    background: #F7FBFE;
    // background-image: url(../images/pattern+bg.png);
    background-image: url(../images/pattern+bg.png);
    background-repeat: no-repeat;
    background-blend-mode: luminosity;
    // opacity: 0.5;
}

// Components

// Thumbnail
.thumbnail{
    display: flex;
    // width: 427px !important;
    height: 578px !important;
    justify-content: flex-end;
    background-color: #0000001c;
    background-blend-mode: multiply;
    background-size: 150vh;
    background-position: center;


    .title{
        // background: #00000000;
        background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 0%);
    }

    .desc{
        margin-bottom: -20%;
        color: transparent;

    }

    &:hover{
        background-size: 130vh;
        transition: all 800ms ;
        .desc{
            color: #ffffff;
            margin-bottom: 0%;
        }

        .title{
            transition: all 700ms ;
            filter: blur(20);
            background: linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 70%);
        }
    }
    
}



.consultation{
    background-image: url(../images/Consultation.webp);
    background-repeat: no-repeat;
}

.solution-section{
    padding-top: 15rem !important;
    padding-bottom: 10rem !important;
}

.solution-section,
.s-inner{
    background-color: #F7FBFE;
    // height: 723px;
    overflow: hidden;

    img,h4,p, .chip, .chip-fixed{
        z-index: 4;
    }
}

// Chip

.chip{
    border: 1px solid #efeff3;
    box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
    width: 348px;
}

.chip-fixed{
    border: 1px solid #efeff3;
    box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
    width: 348px;
    
}

.mobile-nav{
    position: fixed;
    width: 100%;
    height: 100vh;
    z-index: 2000;
    
}

.mobile-overlay{
    width: 100%;
    position: fixed;
    height: 100vh;
    z-index: 1;
    background-color: #000000a8;
    backdrop-filter: blur(20px);
}

.crescent{
    width: 587px;
    max-width: 100%;
    height: 587px;
    border-radius: 100%;
    position: absolute;
    // z-index: -2;
    // background-color: #fff;
    background: linear-gradient(180deg, rgba(76, 155, 215, 0.348) -77.97%, rgba(247, 251, 254, 0.42) 57.56%);
}

.high-contrast{
    border-radius: 12px;
    padding: 12px;

    &:hover{
        // user-select: all;
        color: #ffffff !important;
        background-color: #474747;
        transform: scale(1.01);
        box-shadow: 1px 2px 2px rgba(191, 207, 217, 0.15);
    }
}

.teammate img {
    filter: grayscale(1);
    transition: all 500ms ease;

    &:hover{
        filter: grayscale(0);
    }
}

.project-card{
    display: flex;
    // grid-template-columns: 1fr 1fr;
    // width: 592px;
    padding: 16px;
    gap: 16px;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 8px;
    border: 1px solid #EFEFF3;
    background: #FFF;
    box-shadow: 0px 1px 5px 2px rgba(241, 241, 241, 0.50);

    .image{
        height: 391.597px;
        width: 100%;
        
        border-radius: 10px;
        overflow: hidden;
    }

    &:hover{
        .image img{
            margin-top: 30px;
        }
    }
}

.bgPurple{
    background-color: #F6F4FB;
}

.bgBlue{
    background-color: #F6FBFE;
}


.tag{
    display: flex;
    padding: 6px 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    font-size: 14px;
    border-radius: 50px;
    width: max-content;
    color: #7E7E7E;
    border: 1px solid #F1F1F1;
    background: linear-gradient(180deg, #FFF 0%, #F7F7F7 100%);
    box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.05);

    &.alt{
        background: rgba(255, 255, 255, 0.20);
        box-shadow: -1px 1px 3px 0px rgba(0, 0, 0, 0.05);
        border: none;
        color: #ffffff;
        padding: 4px 10px;
    }
}


.jumbotron{
    background: url(../images/glass+effect.webp);
    background-repeat: no-repeat;
    background-position-x: 50vh;
    background-position-y: center;
    background-size: cover;
    background-color: #A0CFFF;
    

    h4{
        color: #002E5C;
    }

    p{
        color: #3C638B;
    }
}

.about-desc{
    // width: 271.422px;

    font-size: 14px;
    background: linear-gradient(180deg, #808080 0%, rgba(128, 128, 128, 0.00) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.nav-link{
    color: #808080;
    font-size: 14px;
    transition: all 300ms ease;

    &:hover{
        color: var(--primary-color);
        text-decoration: underline;
    }
}

.contact-hero{
.hero-inner{
    max-width: 50%;
}
}


.socials{
    img{
        height: 24px;
    }
}

.client-section{

    background-image: url(../images/pattern+bg+flipped.png);
    background-repeat: no-repeat;
    background-position: right;
    // height: 80vh;
}

.client{
    display: flex;
    // width: 162px;
    border: 1px solid #ffffff;
    height: 133px;
    padding: 44.976px 11.048px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    filter: grayscale(1);
    background: #FFF;
    box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);

    &:hover{
        border: 1px solid var(--primary-color);
        filter: grayscale(0);
        img{
            transform: scale(0.9);
        }
    }
}

.approach-section{
    background: linear-gradient(
    90deg, rgba(0, 46, 92, 0.92) 0%, 
    rgba(20, 86, 132, 0.92) 100%),
    url(../images/team+bg.webp) 50%;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: visible;
    background-attachment: fixed;

    .approach{
        overflow: visible;
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }
        
    }
}

.approach-text{
    top: 1;
    overflow: visible;
    height: 300px;
    
}

.contact-section{
    background: linear-gradient(
    90deg, rgba(0, 46, 92, 100) 0%, 
    rgba(20, 86, 132, 100) 100%);
    // url(../svgs/bg/map-bg2.svg) 50%;
    background-size: contain;
    background-repeat: no-repeat;
    height: 100dvh;
    position: relative;
    overflow: hidden;
}

    .map{
        bottom: 10%;
        right: -25%;
        height: 450px;
        opacity: 0.4;
        position: absolute;
    }

.project-section{
    background: linear-gradient(
    90deg, rgba(0, 46, 92, 0.92) 10%, 
    rgba(20, 86, 132, 0.92) 100%);
    background-size: cover;
    background-repeat: no-repeat;
    height: 100dvh;
}

.blue-alt-card,
.blue-card{
    height: 265px;
    padding: 32px 33px 51px 32px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    align-self: stretch;
    border-radius: 9px;
    background: #094A80;
    box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.10);
}


.form{
    display: flex;
    padding: 24px 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    border-radius: 15px;
    background: #FCFCFC;

    .f-inner{
        display: flex;
        width: 580px;
        padding: 16px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        border-radius: 10px;
        border: 1px solid #EFEFF3;
        background: #FFF;
    }
}

.form-control{
    display: flex;
    position: relative;
    outline: none;
    padding: 12px  12px 16px;
    align-items: center;
    align-self: stretch;
    border-radius: 7px;
    border: 1px solid #F1F1F1;
    background: #FFF;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.03);

    &:focus{
        border-color: var(--primary-color);
    }

}

.form-group{
    position: relative;
    .form-control{
        padding-left: 45px;
    }
    
    img{
        position: absolute;
        top: 50%;
        left: 2%;
        // transform: translateY(-50%);
    }
}


.story-section{
    background: linear-gradient(
    90deg, rgba(0, 46, 92, 100) 0%, 
    rgba(20, 86, 132, 100) 100%),
    url(../svgs/bg/glass-effect.svg) 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-x: left;
    height: 100vh;
    position: relative;
    overflow: hidden;

    .glass-effect{
        top: -40%;

        left: -30%;
        height: 130rem;
        // opacity: 0.4;
        position: absolute;
    }
}

.mission-section{
    background-image: url(../images/pattern+bg.png);
    background-repeat: no-repeat;
    height: 100vh;
}

.single-service-section,
.values-section{
    background: linear-gradient(
    90deg, rgba(0, 46, 92, 0.92) 0%, 
    rgba(20, 86, 132, 0.92) 100%),
    url(../svgs/bg/shadow-effect.svg) 50%;
    color: #ffffff;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: left;
    // height: 100vh;
}

.features-section,
.service-list,
.about-us-section{
    background-image: url(../svgs/bg/wave+bg.svg);
    background-repeat: no-repeat;
    background-color: #ffffff !important;
    background-size: cover;
}

.features-section,
.service-list{
    background-size: contain;
}

.services-top-section{
    background: 
    linear-gradient(180deg, #000 0%, rgba(0, 0, 0, 0.00) 20%), 
    linear-gradient(0deg, #000 0%, rgba(0, 0, 0, 0.00) 50%), 
    linear-gradient(90deg, rgba(0, 46, 92, 0.50) 0%, rgba(20, 86, 132, 0.50) 100%), 
    linear-gradient(90deg, rgba(0, 46, 92, 0.50) 0%, rgba(20, 86, 132, 0.50) 100%);
    // url(../gifs/services+vid.gif);
    height: 100dvh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position-x: right;
    z-index: 2000;
    background-size: contain;

    .hero-texts{
        color: #ffffff;
        max-width: 674px;
        text-align: center;
    }
}

.services-list{
    // background-image: url(../svgs/icons);
}

.swiper-button-prev,
.swiper-button-next {
    background: #00000029;
    border-radius: 50px;
    padding: 30px;
    color: #fff !important;

    &::after{
        font-size: 24px !important;
    }
}

.service-card{
    height: 514px;
    background-size: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    position: relative;
    
    // filter: grayscale(1);


    &::after{
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: #007dd67a;
        transition: all 200ms ease-in;
    }

    .details{
        margin-bottom: -15%;
        padding: 32px;
        background-color: #ffffff;
        border-radius: 3px;
        border: 1px solid var(--Neutral-colors-400, #E1E4ED);
        background: var(--Neutral-colors-100, #FFF);
        max-width: 90%;
        /* Neutral/Shadow 02 */
        box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);
        z-index: 100;
        
        a{
            filter: grayscale(0) !important;
        }
    }

    &:hover{
        .details{
            margin-bottom: -10%;
        }
        
        // background-size: 150%;
        filter: grayscale(0);
        

        &::after{
            background-color: transparent;
        }
    }
}


.single-service-section{
    .big-image{
        height: 80vh;
        width: 100vw;
        background-image: url(../images/Consultation.webp);
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        // filter: grayscale(1);
        
        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: #007dd64d;
            transition: all 500ms ease;
        }
    }
}

.features-card{
    // width: 392px;
    
    padding: 16px;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 3px;
    background: var(--Neutral-colors-100, #FFF);

    /* Neutral/Shadow 02 */
    box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);

    .image{
        position: relative;
        overflow: hidden;
        height: 300px;

        img{
            filter: grayscale(1);
            // height: 100vh;
            // width: 100vw;
            width: 100%;
            // height: 100%;
        }

        &::after{
            content: '';
            position: absolute;
            bottom: 0;
            height: 100%;
            width: 100%;
            background-color: #007dd64d;
            transition: all 500ms ease;
        }
    }

    &:hover{
        .image{
            &::after{
                height: 0%;
                background-color: #007dd693;
                // filter: blur(5px);
            }
        }

        img{
            transform: scale(1.1);
            filter: grayscale(0);
        }
    }   
}

.benefit-card{
    
    padding: 40px 24px;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    align-self: stretch;
    border-radius: 2px;
    background: #F8F8F8;

    /* Neutral/Shadow 02 */
    box-shadow: 0px 1px 4px 0px rgba(25, 33, 61, 0.08);

    .position{
        color: var(--Foundation-Grey-G50, #E9E9E9);
        font-size: 49px;
        font-style: normal;
        font-weight: 400;
    }
}

.jumbotron-alt{
    display: flex;
    overflow: hidden;
    position: relative;
    height: 400px;    
    align-items: center;
    position: relative;
    z-index: 1000;


    &:hover{
        video{
            filter: blur(20px);
        } 
    }
    
    &::after{
        content: '';
        position: absolute;
        bottom: 0;
        z-index: 0;
        height: 100%;
        width: 100%;
        // background-color: #007dd64d;
        transition: all 500ms ease;
        // border-radius: var(--Badge, 2px);
        background: rgba(0, 0, 0, 0.70) 100%;
    }

    .info{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
    }
}

@media only screen and (max-width: 768px) {
    /* For mobile phones: */
    .heading-three{
        font-size: 21px;
    }

    p{
        font-size: 13px;
    }

    .jumbotron-alt::after{
        top: 50%;
        transform: translateY(-50%);
        height: 58%;
    }
    
    .features-card .image {
        height: 200px;
    }
    

    .scroll-cta{
        position: absolute;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
    
        .text{
            color: #ffffff;
        }
    }

    .contact-section{
        height: 100%;
    }

    .form{
        width: 100%;
        padding: 8px;

        form{
            width: 100%;
        }
    }

    .form .f-inner{
        width: 100%;
    }

    .single-service-section{
        height: 100%;
    }

    .jumbotron-alt{
        video{
            width: 50rem !important;
        }

        .info{
            width: 100%;
        }
    }
    
    .services-top-section{
        background-size: cover;
        background-position-x: center;
    }
    .hero-section{
        background-size: cover;
    }

    .blue-alt-card,
    .blue-card{
        height: max-content;
        padding: 24px;
    }

    .values-section,
    .mission-section,
    .story-section{
        height: 100%;
    }

    .vision-vid,
    .mission-vid{
        
        video{
            height: 230px !important;
        }
    }

    .project-card .image{
        height: 200px;
    }

    .project-card .logo{
        height: 32px !important;
    }

  }